@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(5deg);
  }
  70% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes sonar {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
body {
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  background-color:  #f9fbfd;
  color: #495057;
}
.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand > a,
.sidebar-wrapper .sidebar-dropdown > a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.page-wrapper .page-content,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar {
  transition: all 0.3s ease;
}

/*----------------page-wrapper----------------*/

.page-wrapper {
  height: 100vh;
}

.page-wrapper .theme {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 4px;
  margin: 2px;
}

.page-wrapper .theme.chiller-theme {
  background: #1e2229;
}

/*----------------toggeled sidebar----------------*/

.page-wrapper.toggled .sidebar-wrapper {
  left: 0px;
}

/*----------------show sidebar button----------------*/
#show-sidebar {
  position: fixed;
  left: 0;
  top: 10px;
  border-radius: 0 4px 4px 0px;
  width: 35px;
  transition-delay: 0.3s;
}
.page-wrapper.toggled #show-sidebar {
  left: -40px;
}
/*----------------sidebar-wrapper----------------*/
.sidebar-wrapper ul li a span{
  display: none;
}
.sidebar-wrapper {
  width: 70px;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 80px;
  left: -300px;
  z-index: 999;
  transition: width 0.1s;
}

.sidebar-wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-wrapper a {
  text-decoration: none;
}

/*----------------sidebar-content----------------*/

.sidebar-content {
  max-height: calc(100% - 30px);
  height: calc(100% - 30px);
  overflow-y: auto;
  position: relative;
}

.sidebar-content.desktop {
  overflow-y: hidden;
}

/*--------------------sidebar-brand----------------------*/

.sidebar-wrapper .sidebar-brand {
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.sidebar-wrapper .sidebar-brand > a {
  text-transform: uppercase;
  font-weight: bold;
  flex-grow: 1;
}

.sidebar-wrapper .sidebar-brand #close-sidebar {
  cursor: pointer;
  font-size: 20px;
}
/*--------------------sidebar-header----------------------*/

.sidebar-wrapper .sidebar-header {
  padding: 20px;
  overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic {
  float: left;
  width: 60px;
  padding: 2px;
  border-radius: 12px;
  margin-right: 15px;
  overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.sidebar-wrapper .sidebar-header .user-info {
  float: left;
}

.sidebar-wrapper .sidebar-header .user-info > span {
  display: block;
}

.sidebar-wrapper .sidebar-header .user-info .user-role {
  font-size: 12px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status {
  font-size: 11px;
  margin-top: 4px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status i {
  font-size: 8px;
  margin-right: 4px;
  color: #5cb85c;
}

/*-----------------------sidebar-search------------------------*/

.sidebar-wrapper .sidebar-search > div {
  padding: 10px 20px;
}

/*----------------------sidebar-menu-------------------------*/

.sidebar-wrapper .sidebar-menu {
  padding-bottom: 10px;
}
.sidebar-wrapper .sidebar-menu .header-menu p span {
  font-weight: bold;
  font-size: 14px;
  padding: 15px 20px 5px 20px;
  opacity: 0;
  transition-duration: 0.5s;
}

.sidebar-wrapper .sidebar-menu .header-menu p::after{
  content: "";
  height: 1px;
  background: rgba(151,167,193,.5);
  position: absolute;
  border: 1px solid rgba(151,167,193,.5);
  width: 40px;
  left: 16px;
  opacity: 1;
  transition-duration: 1s
}

.sidebar-wrapper .sidebar-menu ul li a {
  display: flex;
  width: 100%;
  text-decoration: none;
  position: relative;
  padding: 5px 20px;
  height: 50px;
}

.sidebar-wrapper .sidebar-menu ul li a i {
  margin-right: 10px;
  font-size: 12px;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 4px;
  padding: 8px 22px 0px 10px;
}

.sidebar-wrapper .sidebar-menu ul li a:hover > i::before {
  display: inline-block;
  animation: swing ease-in-out 0.5s 1 alternate;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown > a:after {
  font-family: "FontAwesome";
  font-weight: 900;
  content: "\f105";
  font-style: normal;
  display: none;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: 0 0;
  position: absolute;
  right: 15px;
  top: 14px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
  padding: 5px 0;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
  padding-left: 25px;
  font-size: 13px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before {
  content: "\f10c";
  font-family: "FontAwesome";
  font-weight: 400;
  font-style: normal;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
  font-size: 8px;
}

.sidebar-wrapper .sidebar-menu ul li a span.label,
.sidebar-wrapper .sidebar-menu ul li a span.badge {
  float: right;
  margin-top: 8px;
  margin-left: 5px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
  float: right;
  margin-top: 0px;
}

.sidebar-wrapper .sidebar-menu .sidebar-submenu {
  display: none;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after {
  transform: rotate(90deg);
  right: 17px;
}

.sidebar-wrapper:hover{
  width: 250px;
  transition: width 0.5s;
    animation: elements 10s infinite linear;
}
.sidebar-wrapper:hover ul li a span{
  display: flex;
  animation: elements 10s infinite linear;
  transition: width 0.1s;
}
.sidebar-wrapper:hover .sidebar-menu .sidebar-dropdown > a:after{
  display: flex;
}
.sidebar-wrapper:hover .sidebar-menu .header-menu p span {
  opacity: 1;
  transition-duration: 0.5s
}

.sidebar-wrapper:hover .sidebar-menu .header-menu p::after{
  opacity: 0
}
/*--------------------------side-footer------------------------------*/

.sidebar-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
}

.sidebar-footer > a {
  flex-grow: 1;
  text-align: center;
  height: 30px;
  line-height: 30px;
  position: relative;
}

.sidebar-footer > a .notification {
  position: absolute;
  top: 0;
}

.badge-sonar {
  display: inline-block;
  background: #980303;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  position: absolute;
  top: 0;
}

.badge-sonar:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid #980303;
  opacity: 0;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: sonar 1.5s infinite;
}

/*--------------------------page-content-----------------------------*/

.page-wrapper .page-content {
  display: inline-block;
  width: 100%;
  padding-left: 0px;
  height: 100%;
}

.page-wrapper .page-content {
  overflow-x: hidden;
}

/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #525965;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #525965;
}
::-webkit-scrollbar-thumb:active {
  background: #525965;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}


/*-----------------------------chiller-theme-------------------------------------------------*/

.chiller-theme .sidebar-wrapper {
    background: #002b49;
}

.chiller-theme .sidebar-wrapper .sidebar-header,
.chiller-theme .sidebar-wrapper .sidebar-search,
.chiller-theme .sidebar-wrapper .sidebar-menu {
    border-top: 1px solid #3a3f48;
}

.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
    border-color: transparent;
    box-shadow: none;
}

.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-role,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-status,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text,
.chiller-theme .sidebar-wrapper .sidebar-brand>a,
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a,
.chiller-theme .sidebar-footer>a {
    color: #f6f6e9;
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li:hover>a,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info,
.chiller-theme .sidebar-wrapper .sidebar-brand>a:hover,
.chiller-theme .sidebar-footer>a:hover i {
    color: #f6f6e9;
}

.page-wrapper.chiller-theme.toggled #close-sidebar {
    color: #bdbdbd;
}

.page-wrapper.chiller-theme.toggled #close-sidebar:hover {
    color: #ffffff;
}

.chiller-theme .sidebar-wrapper ul li:hover a i,
.chiller-theme .sidebar-wrapper .sidebar-dropdown .sidebar-submenu li a:hover:before,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu:focus+span,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active a i {
    color: #16c7ff;
    text-shadow:0px 0px 10px rgba(22, 199, 255, 0.5);
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li a i,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown div,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
    background: #3a3f48;
}

.chiller-theme .sidebar-wrapper .sidebar-menu .header-menu span {
    color: #6c7b88;
}

.chiller-theme .sidebar-footer {
    background: #3a3f48;
    box-shadow: 0px -1px 5px #282c33;
    border-top: 1px solid #464a52;
}
.head-bar{
  box-shadow: 0 4px 16px 0 rgba(153, 187, 221, 0.33);
  height: 80px;
  padding: 0px 30px;
  /* box-shadow: 0 15px 20px -20px rgba(153,187,221,0.1),0 0 15px rgba(153,187,221,0.06); */
}


.chiller-theme .sidebar-footer>a:first-child {
    border-left: none;
}

.chiller-theme .sidebar-footer>a:last-child {
    border-right: none;
}
.card{
  border: none;
  box-shadow: 0 15px 20px -20px rgba(114,162,208,0.1), 0 0 15px rgba(115,162,208,0.06)
}
.head-icon{
  color: #495057;
}
.files input {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    padding: 120px 0px 85px 35%;
    text-align: center !important;
    margin: 0;
    width: 100% !important;
}
.files input:focus{     outline: 2px dashed #92b0b3;  outline-offset: -10px;
    transition: outline-offset .15s ease-in-out, background-color .15s linear; border:1px solid #92b0b3;
 }
.files{ position:relative}
.files:after {  pointer-events: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 50px;
    right: 0;
    height: 56px;
    content: "";
    background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
    display: block;
    margin: 0 auto;
    background-size: 100%;
    background-repeat: no-repeat;
}
.color input{ background-color:#f1f1f1;}
.files:before {
    position: absolute;
    bottom: -15px;
    left: 0;  pointer-events: none;
    width: 100%;
    right: 0;
    height: 57px;
    content: " or drag it here. ";
    display: block;
    margin: 0 auto;
    color: #2ea591;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
}
.btn-primary{
  background: #005792;
  border-color: #005792;
  padding: 10px 20px;
}
.btn-primary:hover,.btn-primary:active{
  background:#00395f;
  border-color: #00395f;
}
/*----react dropdown tree------*/
/* bootstrap-demo is a custom classname to increases the specificity of our styles. It can be anything.
 * The idea is that it is easy to extend/override builtin styles with very little effort.
 */
.bootstrap-demo .dropdown-trigger {
  border-radius: 0.25rem;
}

.bootstrap-demo .dropdown-trigger > span:after {
  font-size: 12px;
  color: #555;
}

.bootstrap-demo .toggle {
  font: normal normal normal 12px/1 FontAwesome;
  color: #555;
}

.bootstrap-demo .toggle.collapsed::after {
  content: "\f067";
}

.bootstrap-demo .toggle.expanded::after {
  content: "\f068";
}

.bootstrap-demo .root {
  padding: 0px;
  margin: 0px;
}
.bootstrap-demo ul{
  list-style-type: none;
}
.react-dropdown-tree-select .dropdown{
  width: 100%;
  display: block;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger{
  width: 100%;
  background: #fff;
}
.tag-list{
  display: inline-block !important;
  width: 95%;
}
.dropdown-trigger .tag-item .search{
  border-bottom: 0;
  width: 100%;
}
.dropdown-trigger .tag-item{
  width: 100%;
}
.react-dropdown-tree-select .dropdown .dropdown-content{
  width: 100%;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after{
  margin-right: 0px !important;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after{
  margin-right: 0px !important;
}
/*--React end dropdown tree --*/
.form_wizard_body .tab-content{
  margin-top:5%;
  background: #d6dee5;
  border-radius: 3px;
  padding: 15px;
}
.form_wizard{
  margin-right: -5px;
  margin-left:-5px;
}
.form_wizard{
  border:none !important;
}
.form_wizard  a,.form_wizard .nav-link.disabled{
  text-transform: capitalize;
  padding: 10px 20px;
  background: #d6dee5;
  color:#495057;
  margin: 0 5px;
  border-radius: 3px !important;
}
.form_wizard  a.active{
  color: #f6f6e9 !important;
  background-color: #002b49 !important;
  border-color: #002b49 !important;
  border-radius: 3px !important;
}
.gallery:hover {
  background-image: none;

  opacity:0.5;
  z-index: 999;
  color: #000;
  font-size: 16px;
}

.gallery:hover::after{
  content:attr("data-label");
  background: #000;
  color: #fff;
}
.blur-icon{
  color: #999;
}
.project_number{
  background: #f1f6fa;
  width: 50px;
  height: 50px;
  padding: 10px;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
}
.project_detail_heading{
  font-size: 12px;
}
.project_status_ul{
  list-style: none;
  padding: 0;
}
.project_status_ul li{
  padding-left: 1.3em;
  color: #ddd;
}
.project_status_ul li.done{
  color: #333;
}
.project_status_ul li.done::before{
  color: #17ca8c;
}

.project_status_ul li:before{
  content: "\2713"; /* FontAwesome Unicode */
  display: inline-block;
  margin-left: -1.3em; /* same as padding-left set on li */
  width: 1.3em; /* same as padding-left set on li */
  /* #17ca8c */
}
.analysis_tab .tab-content{
  padding: 0px !important;
}
.analysis_tab .tab-content .card{
  box-shadow: none;
}
.image_gallery {
  padding-top:0px;
  padding-bottom: 0px;
}
.img_container:hover .overlay {
  opacity: 1;
}
.overlay {
  position: absolute;
  bottom: 0;
  left: 15px;
  right: 0;
  height: 40%;
  width: 85%;
  opacity: 0;
  transition: .5s ease;
  background-color: #002b49;
}
.image_text {
  color: white;
  font-size: 12px;
  text-transform: capitalize;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  word-wrap: break-word;
}

.thumbnail{
  width: 100%;
}
.modal-body {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}
.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.red-bg{
  background: var(--blue);
}
.blue-bg{
  background: var(--indigo);
}
.slice {
  cursor: pointer;
}

.slice .main-arc {
    stroke: #fff;
    stroke-width: 1px;
}

.slice .hidden-arc {
    fill: none;
}

.slice text {
    pointer-events: none;
    dominant-baseline: middle;
    text-anchor: middle;
}
.node rect {
  cursor: move;
  fill-opacity: .9;
  shape-rendering: crispEdges;
}

.node text {
  pointer-events: none;
  text-shadow: 0 1px 0 #fff;
}

.link {
  fill: none;
  stroke: #000;
  stroke-opacity: .2;
}

.link:hover {
  stroke-opacity: .5;
}
.testItem{
  display: inline-block;
}

.inlineTab{
  display: block;
  overflow: hidden;
  white-space: nowrap;
}
.inlineTab div{
  display: inline-block;
}

.arrow_circle{
  background: aliceblue;
  padding: 10px 14px;
  border-radius: 50%;
  cursor: pointer;
}
.snpefftable tr td{
  word-break: break-all;
  white-space: normal;
}
.note {
	border: 1px solid #aaa;
	background-color: #eeeeee;
	padding: 5px;
	font-size: 90%;
	text-align: left;
}

.popover {
  max-width: 1000px;
  margin-top: 85px;
  overflow: scroll;
  max-height: 500px;
  z-index:9999999999;
}

/**BOotstrap overwite**/
.form-label{
  color: #495057;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif !important;
}
.form-control,.dropdown-trigger {
  font-family: "Montserrat", sans-serif !important;
  border:1px solid #c1ccd3;
  border-color: rgb(193, 204, 211);

  border-radius: .3rem;
  box-shadow: none;
  color: #495057;
}
.form-control:focus,.form-control:active,.dropdown-trigger:focus,.dropdown-trigger:active{
  border-color: #4d90fe !important;
  box-shadow: none !important;
}
.searchbox{
  border: none !important;
}
table thead{
  background: #005792;
  color: #fff;
  text-transform: uppercase;
  border: 1px solid #005792;
}
.card{
  box-shadow: 0 23px 20px -20px rgba(115,162,208,0.1),0 0 15px rgba(115,162,208,0.06);
}
h1,h2,h3,h4,h5,h6{
  font-family: "Montserrat", sans-serif !important;
}
.edit,.edit:hover{
  background-color:#21ae8c;
  color: #fff;
  border-color: #21ae8c;
}
.delete,.delete:hover{
  background-color: #fd5f00;
  color: #fff;
}
.table thead > tr > th {
  border-bottom: none;
}
table tbody tr  td{
  text-transform: capitalize;
}
.btn.focus, .btn:focus{
  box-shadow:none;
}
/**BOotstrap overwite**/
.filter_viz{
  top:6px !important;
  z-index: 1;
}
.filter_viz > .tooltip-inner{
  max-width: 300px;
  background: #fff;
  border: 1px solid #21ae8c;
  text-align: left;
}
.filter_viz .arrow::before{
  border-width: 0 0.7rem 0.7rem;
  border-bottom-color: #21ae8c;
}
.bg-secondary{
  background: #005792 !important;
  color: #fff;
}
.bg-custom-color-nav{
  background: #d6dee5 !important;
}
.page-item.active .page-link{
  background-color: #005792;
  border-color: #005792;
}
.page-link{
  color: #005792;
}
.card-body table thead{
  background: #fff;
  color: #000;
  border-color: #fff;
}
#anatomogram_parent svg{
  height: 500px !important;
}
.pagination{
  float: right;
}

